<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Financial Transactions</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Financials' }"
              >Financial</router-link
            >
          </li> 
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Financial-Transactions-List' }"
              >Transactions</router-link
            >
          </li>
          <li class="breadcrumb-item active">View</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-md-9">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Financial Transaction Details</h4>
          </div>
          <div class="card-body">
            <div class="basic-form">
              
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Type</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_transaction.type }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">Name</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_transaction.name }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">ID Number</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_transaction.id_number }}
                </label>
              </div>

              <div class="form-group row" v-if="financial_transaction.heading">
                <label class="col-md-3 col-form-label">Heading</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_transaction.heading.name }} 
                </label>
              </div>

              <div class="form-group row" v-if="financial_transaction.account_from">
                <label class="col-md-3 col-form-label">From</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_transaction.account_from.name }}
                </label>
              </div>

             <div class="form-group row" v-if="financial_transaction.account_to">
                <label class="col-md-3 col-form-label">To</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_transaction.account_to.name }}
                </label>
              </div>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">Sheet Number</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_transaction.sheet_number }}
                </label>
              </div>

             <div class="form-group row">
                <label class="col-md-3 col-form-label">Amount</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_transaction.amount }}
                </label>
              </div>  
              
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Check Number</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_transaction.check_number }}
                </label>
              </div>
 
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Date</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_transaction.date }}
                </label>
              </div>
 
              <div class="form-group row">
                <label class="col-md-3 col-form-label">Rate</label>
                <label class="col-md-9 col-form-label">
                  {{ financial_transaction.rate }}
                </label>
              </div>
 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useFinancialTransactions from "@/composables/financial_transactions";
import { inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  setup(props) {
    const hasPermission = inject("hasPermission");
    const router = useRouter();
    const { getFinancialTransaction, financial_transaction } = useFinancialTransactions();

    onBeforeMount(async () => {
      //first: check the user Permission
      if (!hasPermission("view_financial_transactions"))
        router.push({
          name: "401",
        });

      await getFinancialTransaction(props.id);
    });

    return {
      financial_transaction,
    };
  },
};
</script>